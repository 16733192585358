import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, concatMap, from, Observable, Subject, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalOperationsStateService } from '@dougs/operations/shared';
import { VendorInvoiceOperationSearchComponentService } from './vendor-invoice-operation-search.component.service';

@Injectable()
export class VendorInvoiceOperationsComponentService {
  private offset = 0;

  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly filterBlockOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filterBlockOpened$: Observable<boolean> = this.filterBlockOpened.asObservable();

  private readonly queueScroll: Subject<void> = new Subject<void>();
  queueScroll$ = this.queueScroll.asObservable();

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly modalOperationsStateService: ModalOperationsStateService,
    private readonly vendorInvoiceOperationSearchComponentService: VendorInvoiceOperationSearchComponentService,
  ) {}

  refreshOperations$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    tap(() => this.isLoading.next(true)),
    tap(() => (this.offset = 0)),
    concatMap((activeCompany) =>
      this.modalOperationsStateService.refreshOperations(activeCompany.id, {
        autogenerated: false,
      }),
    ),
    tap(() => this.isLoading.next(false)),
  );

  loadMoreOperations$: Observable<void> = combineLatest([
    this.companyStateService.activeCompany$,
    this.queueScroll$,
  ]).pipe(
    debounceTime(10),
    tap(() => this.offset++),
    concatMap(([activeCompany, _]) =>
      from(
        this.modalOperationsStateService.loadMoreOperations(
          activeCompany.id,
          {
            ...this.vendorInvoiceOperationSearchComponentService.searchFormGroup.value,
            autogenerated: false,
          },
          this.offset,
        ),
      ),
    ),
  );

  onScroll(): void {
    this.queueScroll.next();
  }

  onFilterBlockToggled(filterBlockOpened: boolean): void {
    this.filterBlockOpened.next(filterBlockOpened);
  }
}
